import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';
import logo from '../assets/images/logo.svg';
import ThemeColor from './ThemeColor';
import SocialMenu from './SocialMenu';

const NavMobileStyled = styled.nav`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: none;
  }
  [data-mobile-header] {
    display: flex;
    align-items: center;
    padding: 2rem;
  }
  .mobile-nav-toggle {
    max-width: 3rem;
    cursor: pointer;
  }
  .mobile-nav-toggle span {
    display: block;
    height: 2px;
    width: 3rem;
    background: var(--textColor);
    transition: all calc(var(--speed) * 2);
    transform-origin: center;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
  .mobile-nav-toggle.open span {
    transform-origin: center;
    margin: 0;
    &:first-of-type {
      transform: rotate(-45deg);
      margin-bottom: -1px;
    }
    &:last-of-type {
      transform: rotate(45deg);
    }
  }
  .mobile-nav {
    background-color: var(--background);
    width: 100%;
    transform: translateY(-100%);
    height: 0px;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
  }
  .mobile-nav.open {
    transform: translateY(0%);
    height: auto;
    visibility: visible;
    opacity: 1;
  }
  ul {
    margin: 0;
    padding: 0 2rem 2rem;
    list-style: none;
    width: 100%;
  }
  li {
    display: block;
    border-bottom: 1px solid var(--textColor);
    padding: 2rem 0;
    &:first-of-type {
      border-top: 1px solid var(--textColor);
    }
  }
  .logo {
    text-align: left;
    margin-right: auto;
  }
  .logo img {
    max-height: 25px;
    min-width: 100px;
  }
  a {
    color: var(--textColor);
    text-transform: uppercase;
    text-decoration: none;
    font-family: RobotoCondensedBold;
    transition: all var(--speed);
    &:hover {
      color: var(--watermelon);
      text-decoration: underline;
    }
    &[aria-current='page'] {
      color: var(--watermelon);
    }
  }
`;

export default function Nav() {
  const [navState, navToggle] = useState(false);

  function toggleNavigation() {
    navToggle(!navState);
  }

  return (
    <NavMobileStyled>
      <div data-mobile-header>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Bont Rowing Logo" />
          </Link>
        </div>
        <div
          className={navState ? 'mobile-nav-toggle open' : 'mobile-nav-toggle'}
          role="button"
          tabIndex={0}
          onClick={toggleNavigation}
          aria-hidden="true"
        >
          <span />
          <span />
        </div>
      </div>
      <div className={navState ? 'mobile-nav open' : 'mobile-nav'}>
        <ul>
          <li>
            <Link to="/products/" onClick={() => navToggle(false)}>
              Products
            </Link>
          </li>
          <li>
            <Link to="/technology/" onClick={() => navToggle(false)}>
              Technology
            </Link>
          </li>
          <li>
            <Link to="/support/" onClick={() => navToggle(false)}>
              Support
            </Link>
          </li>
          <li>
            <Link to="/approach/" onClick={() => navToggle(false)}>
              Approach
            </Link>
          </li>
          {/* <li>
            <Link to="/news/">News</Link>
          </li> */}
          <li className="dealers">
            <Link to="/dealers/" onClick={() => navToggle(false)}>
              Where to buy
            </Link>
          </li>
        </ul>
        <SocialMenu />
      </div>
    </NavMobileStyled>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavInfoStyled = styled.div`
  ul {
    list-style: none;
    margin: 0 0 1rem;
    padding: 0;
  }
  li {
    padding: 1rem 0;
    text-transform: uppercase;
    text-align: right;
    padding: 0 0 0.5rem;
    a {
      color: var(--watermelon);
    }
  }
  @media (max-width: 390px) {
    h4:first-of-type {
      border-top: transparent;
    }
  }
  a.h4 {
    padding-bottom: 2rem;
  }
  a.h4:last-of-type {
    border-bottom: 1px solid var(--white);
  }
  a {
    color: var(--white);
    text-decoration: none;
    cursor: pointer;
  }
  p {
    margin: 0 0 1rem;
  }
`;

export default function NavProduct({ technology, support }) {
  const openBeacon = () => {
    FrontChat('show');
  };
  return (
    <NavInfoStyled>
      <h4>{technology.nodes[0].title}</h4>
      <ul>
        {technology.nodes[0].technologyPages.map((item) => (
          <li>
            <Link to="/technology/">{item.title}</Link>
          </li>
        ))}
      </ul>
      <h4>{support.nodes[0].title}</h4>
      <ul>
        <li>
          <Link to="/support/size-chart">Shoe Size Finder</Link>
        </li>
        <li>
          <a
            href="https://help.bontrowing.com/en-US/i-need-to-submit-a-warranty-claim-127865"
            target="_blank"
            rel="noreferrer"
          >
            Warranty
          </a>
        </li>
        <li>
          <a
            href="https://help.bontrowing.com/"
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </a>
        </li>
        <li>
          <a href={void 0} role="button" onClick={openBeacon}>
            Contact Us
          </a>
        </li>
        <li>
          <Link to="/downloads/">Downloads</Link>
        </li>
      </ul>
      <Link className="h4" to="/approach">
        Approach
      </Link>
      <Link className="h4" to="/dealers">
        Where to buy
      </Link>
    </NavInfoStyled>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavProductStyled = styled.div`
  ul {
    border-bottom: 1px solid var(--white);
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    border-top: 1px solid var(--white);
    padding: 1rem 0;
    text-transform: uppercase;
  }
  ul li ul,
  li:first-of-type {
    border: none;
  }
  li ul li {
    border-top: none;
    text-align: right;
    padding: 0 0 0.5rem;
    a {
      color: var(--watermelon);
    }
  }
  a {
    color: var(--white);
    text-decoration: none;
  }
  p {
    margin: 0 0 1rem;
  }
  @media (max-width: 767px) {
    margin-bottom: -4rem;
  }
`;

export default function NavProduct(props) {
  return (
    <NavProductStyled>
      <h4>Products</h4>
      <ul>
        <li>
          <Link to="/categories">Category</Link>
          <ul>
            {props.categories.nodes.map((category) => (
              <li key={category.id}>
                <Link to={`/category/${category.slug.current}`}>
                  {category.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link to="/series">Series</Link>
          <ul>
            {props.series.nodes.map((item) => (
              <li key={item.id}>
                <Link to={`/series/${item.slug.current}`}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </NavProductStyled>
  );
}

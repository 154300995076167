import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';
import ThemeColor from './ThemeColor';

const NavStyled = styled.nav`
  ul {
    margin: 0 auto;
    padding: 2rem 0;
    list-style: none;
    max-width: var(--wrapper);

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  li {
    display: inline-block;
    &:first-of-type a {
      padding-left: 0;
    }
  }
  .logo {
    text-align: left;
    margin-right: auto;
    width: 200px;
  }
  .logo img {
    max-height: 30px;
    min-width: 100px;
    filter: invert(1) sepia(100%) saturate(10000%) hue-rotate(45deg);
  }
  .dealers {
    width: 200px;
    margin-left: auto;
    a {
      padding-right: 0;
    }
  }
  a {
    color: var(--textColor);
    text-transform: uppercase;
    text-decoration: none;
    font-family: RobotoCondensedBold;
    padding: 2rem;
    transition: all var(--speed);
    font-size: 1.5rem;
    &:hover {
      color: var(--borderColor);
      text-decoration: underline;
    }
    &[aria-current='page'] {
      color: var(--borderColor);
    }
  }
`;

export default function Nav() {
  return (
    <NavStyled>
      <ul className="wrapper">
        <li className="logo">
          <Link to="/">
            <img src={logo} alt="Bont Rowing Logo" />
          </Link>
        </li>
        <li>
          <Link to="/products/">Products</Link>
        </li>
        <li>
          <Link to="/technology/">Technology</Link>
        </li>
        <li>
          <Link to="/support/">Support</Link>
        </li>
        <li>
          <Link to="/approach/">Approach</Link>
        </li>
        <li>
          <Link to="/news/">News</Link>
        </li>
        <li className="dealers">
          <Link to="/dealers/">Where to buy</Link>
        </li>
      </ul>
    </NavStyled>
  );
}

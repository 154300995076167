import React from 'react';
import styled from 'styled-components';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaStrava } from '@react-icons/all-files/fa/FaStrava';

const SocialStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: RobotoCondensedRegular;
  li {
    border: none !important;
    display: inline-block !important;
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  a {
    font-family: RobotoCondensedRegular !important;
    font-weight: normal !important;
    text-transform: capitalize !important;
    &:hover {
      color: var(--watermelon);
    }
    @media (max-width: 1023px) {
      font-size: 1.15rem !important;
    }
  }
  a svg {
    vertical-align: middle;
  }
`;

export default function SocialMenu() {
  return (
    <SocialStyled>
      <li>
        <a
          href="https://instagram.com/bontrowing"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram /> Instagram
        </a>
      </li>
      <li>
        <a
          href="https://facebook.com/bontrowing"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare /> Facebook
        </a>
      </li>
      <li>
        <a
          href="https://www.strava.com/clubs/bontrowing"
          target="_blank"
          rel="noreferrer"
        >
          <FaStrava /> Strava
        </a>
      </li>
    </SocialStyled>
  );
}

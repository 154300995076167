import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

const AnnouncementStyled = styled.header`
  background: var(--blackMatte);
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: var(--white);
    text-decoration: none;
    font-family: inherit;
    font-size: 1.25rem;
    padding: 0.5rem;
    text-align: center;
  }
`;

export default function Announcement() {
  return (
    <AnnouncementStyled>
      <Link to="/news/win-a-pair-of-bont-rowing-custom-amrc24-rowing-shoes">
        WIN A PAIR OF CUSTOM AMRC24 ROWING SHOES
      </Link>
    </AnnouncementStyled>
  );
}

import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Announcement from './Announcement';
import Footer from './Footer';
import Header from './Header';

const MainStyles = styled.main`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  animation: fadein 1s;
`;

export default function Layout({ children }) {
  return (
    <div>
      <GlobalStyles />
      <Typography />
      {/* <Announcement /> */}
      <Header />
      <MainStyles>{children}</MainStyles>
      <Footer />
    </div>
  );
}
